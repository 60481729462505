.button {
  border-radius: 15px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  border: none;
  height: 30px;
  min-width: 95px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 7px;
  }
}

.form-group {
  display: flex;
  margin-top: 0;

  &:not(:first-child) {
    margin-top: 16px;
  }

  &__label {
    text-align: right;
    width: 50%;
    margin-right: 16px;
  }

  &__input, &__textarea, &__select, &__radios {
    width: 50%;
  }

  &s {
    &__list {
      margin-top: 16px;
    }
  }
}
