// SET predefined colors
$setlink_yellow: rgba(251, 176, 52, 1);
$setlink_red: rgba(173, 5, 5, 1);
$setlink_accessible_red: rgba(182, 0, 0, 1);
$setlink_blue: rgba(59, 103, 201, 1);
$setlink_lightblue: rgba(219, 226, 241, 1);
$setlink_grey: rgba(209, 209, 209, 1);
$setlink_lightgrey: rgba(157, 166, 173, 1);
$setlink_darkgrey: rgba(117, 117, 117, 1);
$setlink_black: rgba(35, 31, 32, 1);
$setlink_placeholder: rgba(209, 209, 209, 1);
$setlink_disabled: rgba(138, 138, 138, 1);
$setlink_form_background: rgba(239, 239, 239, 1);
$setlink_link: rgba(44, 92, 200, 1);
$setlink_middlegrey: rgba(95, 96, 98, 1);
$setlink_footergrey: #333;
$setlink_green: rgba(58, 131, 88, 1);

// Other colors
$primary_background: #f7f7f7;
$snapshot_background: #fff;
