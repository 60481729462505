$color-schemes: (
  'red-1': (
    'ref': 'jmart-red',
    'theme-color': hsl(359, 91, 31),
    'base-color': hsl(359, 91, 31),
    'is-base-color-dark': true,
    'is-base-color-greyscale': false
  ),
  'red-2': (
    'ref': 'tkn-red',
    'theme-color': hsl(358, 85, 52),
    'base-color': hsl(358, 85, 52),
    'is-base-color-dark': false,
    'is-base-color-greyscale': false
  ),
  'orange-1': (
    'ref': 'tcap-orange',
    'theme-color': hsl(22, 90, 54),
    'base-color': hsl(22, 90, 54),
    'is-base-color-dark': false,
    'is-base-color-greyscale': false
  ),
  'orange-2': (
    'ref': 'set-orange',
    'theme-color': hsl(37, 97, 60),
    'base-color': hsl(37, 97, 60),
    'is-base-color-dark': false,
    'is-base-color-greyscale': false
  ),
  'pink-1': (
    'ref': 'top-pink',
    'theme-color': hsl(329, 82, 49),
    'base-color': hsl(329, 82, 49),
    'is-base-color-dark': false,
    'is-base-color-greyscale': false
  ),
  'yellow-1': (
    'ref': 'mbket-yellow',
    'theme-color': hsl(46, 100, 52),
    'base-color': hsl(46, 100, 52),
    'is-base-color-dark': false,
    'is-base-color-greyscale': false
  ),
  'brown-1': (
    'ref': 'tps-brown',
    'theme-color': hsl(33, 57, 32),
    'base-color': hsl(33, 57, 32),
    'is-base-color-dark': true,
    'is-base-color-greyscale': false
  ),
  'green-1': (
    'ref': 'kbank-green',
    'theme-color': hsl(148, 100, 33),
    'base-color': hsl(148, 100, 33),
    'is-base-color-dark': false,
    'is-base-color-greyscale': false
  ),
  'green-2': (
    'ref': 'cpf-green',
    'theme-color': hsl(149, 98, 18),
    'base-color': hsl(149, 98, 18),
    'is-base-color-dark': true,
    'is-base-color-greyscale': false
  ),
  'green-3': (
    'ref': 'advanc-green',
    'theme-color': hsl(76, 59, 52),
    'base-color': hsl(76, 59, 52),
    'is-base-color-dark': false,
    'is-base-color-greyscale': false
  ),
  'green-4': (
    'ref': 'sena-green',
    'theme-color': hsl(173, 49, 50),
    'base-color': hsl(173, 49, 50),
    'is-base-color-dark': false,
    'is-base-color-greyscale': false
  ),
  'green-5': (
    'ref': 'bh-green',
    'theme-color': hsl(182, 51, 28),
    'base-color': hsl(182, 51, 28),
    'is-base-color-dark': true,
    'is-base-color-greyscale': false
  ),
  'purple-1': (
    'ref': 'mcot-purple',
    'theme-color': hsl(274, 53, 37),
    'base-color': hsl(274, 53, 37),
    'is-base-color-dark': true,
    'is-base-color-greyscale': false
  ),
  'blue-1': (
    'ref': 'plat-blue',
    'theme-color': hsl(240, 98, 22),
    'base-color': hsl(240, 98, 22),
    'is-base-color-dark': true,
    'is-base-color-greyscale': false
  ),
  'blue-2': (
    'ref': 'cpall-blue',
    'theme-color': hsl(219, 63, 40),
    'base-color': hsl(219, 63, 40),
    'is-base-color-dark': true,
    'is-base-color-greyscale': false
  ),
  'blue-3': (
    'ref': 'dtac-blue',
    'theme-color': hsl(196, 100, 47),
    'base-color': hsl(196, 100, 47),
    'is-base-color-dark': false,
    'is-base-color-greyscale': false
  ),
  'blue-4': (
    'ref': 'bdms-blue',
    'theme-color': hsl(204, 100, 37),
    'base-color': hsl(204, 100, 37),
    'is-base-color-dark': true,
    'is-base-color-greyscale': false
  ),
  'blue-5': (
    'ref': 'seaoil-blue',
    'theme-color': hsl(215, 65, 29),
    'base-color': hsl(215, 65, 29),
    'is-base-color-dark': true,
    'is-base-color-greyscale': false
  ),
  'black-1': (
    'ref': 'black',
    'theme-color': hsl(345, 6, 13),
    'base-color': hsl(0, 0, 0),
    'is-base-color-dark': true,
    'is-base-color-greyscale': true
  ),
  'grey-1': (
    'ref': 'grey',
    'theme-color': hsl(0, 0, 82),
    'base-color': hsl(0, 0, 0),
    'is-base-color-dark': false,
    'is-base-color-greyscale': true
  ),
);
