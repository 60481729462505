@mixin color-scheme($color) {
  $selected-color-scheme: map-get($color-schemes, $color);
  $theme-color: map-get($selected-color-scheme, 'theme-color');
  $base-color: map-get($selected-color-scheme, 'base-color');
  $base-color-80l: change-color($base-color, $saturation: 60%, $lightness: 80%);
  $base-color-85l: change-color($base-color, $saturation: 60%, $lightness: 85%);
  $base-color-90l: change-color($base-color, $saturation: 60%, $lightness: 90%);
  $base-grey-80l: change-color($base-color, $lightness: 80%);
  $base-grey-85l: change-color($base-color, $lightness: 85%);
  $base-grey-90l: change-color($base-color, $lightness: 90%);
  $dark-base: map-get($selected-color-scheme, 'is-base-color-dark');
  $grey-base: map-get($selected-color-scheme, 'is-base-color-greyscale');

  .color-scheme__#{$color} {
    .box {
      &__header {
        background-color: $theme-color;

        &__title {
          @if $dark-base {
            color: $white;
          }

          @else {
            color: $black;
          }
        }

        &__subtitle {
          @if $dark-base {
            color: $white;
          }

          @else {
            color: $black;
          }
        }

        .icon {
          &__info {
            @if $dark-base {
              background-image: url('../../../icons/info-white.svg');
            }

            @else {
              background-image: url('../../../icons/info.svg');
            }
          }

          &__add {
            @if $dark-base {
              background-image: url('../../../icons/add-white.svg');
            }

            @else {
              background-image: url('../../../icons/add.svg');
            }
          }

          &__edit {
            @if $dark-base {
              background-image: url('../../../icons/edit-white.svg');
            }

            @else {
              background-image: url('../../../icons/edit.svg');
            }
          }

          &__visible {
            @if $dark-base {
              background-image: url('../../../icons/visible-white.svg');
            }

            @else {
              background-image: url('../../../icons/visible.svg');
            }
          }

          &__hidden {
            @if $dark-base {
              background-image: url('../../../icons/hidden-white.svg');
            }

            @else {
              background-image: url('../../../icons/hidden.svg');
            }
          }
        }
      }
    }

    .stock-table {
      &__row-header {
        @if $grey-base {
          background-color: $base-grey-80l;
        }

        @else {
          background-color: $base-color-80l;
        }

        color: $black;
      }
    }

    .table-grid {
      &__column-header {
        @if $grey-base {
          background-color: $base-grey-85l;
        }

        @else {
          background-color: $base-color-85l;
        }

        color: $black;

        &:nth-child(2) {
          @if $grey-base {
            background-color: $base-grey-80l;
          }

          @else {
            background-color: $base-color-80l;
          }

          color: $black;
        }
      }

      &__row-header {
        @if $grey-base {
          background-color: $base-grey-90l;
        }

        @else {
          background-color: $base-color-90l;
        }

        color: $black;
      }

      &__cell {
        @if $grey-base {
          background-color: $base-grey-90l;
        }

        @else {
          background-color: $base-color-90l;
        }

        color: $black;

        textarea {
          @if $grey-base {
            background-color: $base-grey-90l;
          }

          @else {
            background-color: $base-color-90l;
          }

          color: $black;
        }

        &:nth-child(2) {
          @if $grey-base {
            background-color: $base-grey-80l;
          }

          @else {
            background-color: $base-color-80l;
          }

          color: $black;

          textarea {
            @if $grey-base {
              background-color: $base-grey-80l;
            }

            @else {
              background-color: $base-color-80l;
            }

            color: $black;
          }
        }
      }
    }

    .color-picker__selector {
      background-color: $theme-color;

      @if $dark-base {
        background-image: url('../../../icons/fill-white.svg');
      }

      @else {
        background-image: url('../../../icons/fill.svg');
      }
    }

    @for $i from 1 through 10 {
      .revenue-chart-bar__colors-shades-of-#{$i} {
        @include n-shades-of($base-color, $grey-base, $i);
      }
    }

    .shareholder-chart-bar__colors-shades {
      @include n-shades-of($base-color, $grey-base, 6);
    }

    .shareholder-bullets__colors-shades {
      @include n-shades-of($base-color, $grey-base, 6);
    }
  }

  .color-picker__pallettes-color-#{$color} {
    background-color: $theme-color;

    &--active {
      @if $dark-base {
        background-image: url('../../../icons/check-mark-white.svg');
      }

      @else {
        background-image: url('../../../icons/check-mark.svg');
      }

    }
  }
}

@mixin n-shades-of($color, $greyscale, $n) {
  @if $n <=6 {
    @for $i from 1 through $n {
      .shade-container:nth-child(#{$i}) {
        .shade {
          @if $greyscale {
            fill: change-color($color, $lightness: $i * 10% + 20%);
          }

          @else {
            fill: change-color($color, $saturation: 60%, $lightness: $i * 10% + 20%);
          }
        }
      }
    }
  }

  @else if $n==7 {
    @for $i from 1 through $n {
      .shade-container:nth-child(#{$i}) {
        .shade {
          @if $greyscale {
            fill: change-color($color, $lightness: $i * 10% + 10%);
          }

          @else {
            fill: change-color($color, $saturation: 60%, $lightness: $i * 10% + 10%);
          }
        }
      }
    }
  }

  @else if $n==8 {
    @for $i from 1 through $n {
      .shade-container:nth-child(#{$i}) {
        .shade {
          @if $greyscale {
            fill: change-color($color, $lightness: $i * 10%);
          }

          @else {
            fill: change-color($color, $saturation: 60%, $lightness: $i * 10%);
          }
        }
      }
    }
  }

  @else if $n>=9 {
    @for $i from 1 through $n {
      .shade-container:nth-child(#{$i}) {
        .shade {
          @if $greyscale {
            fill: change-color($color, $lightness: ($i - 1)* 10%);
          }

          @else {
            fill: change-color($color, $saturation: 60%, $lightness: ($i - 1) * 10%);
          }
        }
      }
    }
  }
}