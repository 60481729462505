@import url('https://fonts.googleapis.com/css2?family=Sarabun&display=swap');

@font-face {
  font-family: 'DB Helvethaica X 55';
  src: url('../../fonts/DBHelvethaicaX-55Regular.eot');
  src: url('../../fonts/DBHelvethaicaX-55Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/DBHelvethaicaX-55Regular.woff2') format('woff2'),
    url('../../fonts/DBHelvethaicaX-55Regular.woff') format('woff'), url('../../fonts/DBHelvethaicaX-55Regular.ttf') format('truetype'),
    url('../../fonts/DBHelvethaicaX-55Regular.svg#DBHelvethaicaX-55Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DB HelvethaicaMon X 55';
  src: url('../../fonts/DBHelvethaicaMonX-55Regular.eot');
  src: url('../../fonts/DBHelvethaicaMonX-55Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/DBHelvethaicaMonX-55Regular.woff2') format('woff2'),
    url('../../fonts/DBHelvethaicaMonX-55Regular.woff') format('woff'), url('../../fonts/DBHelvethaicaMonX-55Regular.ttf') format('truetype'),
    url('../../fonts/DBHelvethaicaMonX-55Regular.svg#DBHelvethaicaMonX-55Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
