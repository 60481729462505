// QuillJS overriding
.ql {
  &-container {
    font-family: $primary_font_family;
    font-size: 11px;
    font-weight: normal;

    &.ql-snow {
      border: none;
    }
  }

  &-editor {
    line-height: 170%;
    padding: 0;

    ol,
    ul {
      padding-left: 0;
    }

    &.ql-blank {
      &::before {
        color: $setlink_placeholder;
        font-family: $primary_font_family;
        font-size: 11px;
        font-weight: normal;
        font-style: normal;
        margin-left: -1rem;
      }
    }
  }

  &-tooltip {
    border-radius: 8px !important;
  }


  &-no-toolbar {
    .ql-toolbar {
      display: none;
    }
  }
}
