@import '../../@assets/scss/all';

.header {
  &__container {
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    height: 45px;
    background: $setlink_yellow;
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
    width: 100%;
    box-sizing: border-box;
    z-index: 4900;

    &--left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: 45px;
      height: 45px;
    }

    &--right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      line-height: 45px;
      font-family: $tertiary_font_family;

      .profile {
        width: 220px;
        margin-right: 15px;
        text-align: right;

        &--name {
          line-height: 24px;
          font-weight: 500;
          font-size: 20px;
        }

        &--roles {
          font-size: 14px;
          line-height: 17px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.setlink-logo {
  cursor: pointer;
}

.rm-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-image: url('../../@assets/icons/rm.svg');
  margin-right: 10px;
}

.i18n-icon {
  width: 20px;
  height: 20px;
  opacity: 0.5;
  cursor: pointer;

  &.active {
    opacity: 1;
    cursor: default;
  }

  &__en {
    background-image: url('../../@assets/icons/flag-en.svg');
  }

  &__th {
    background-image: url('../../@assets/icons/flag-th.svg');
  }
}

.popover {
  display: none;
  position: absolute;
  top: 45px;
  right: 0;
  font-family: $tertiary_font_family;

  &.active {
    display: block;
  }

  &-inner {
    background-color: $white;
    background-clip: padding-box;
    border-radius: 14px;
    line-height: 24px;
    font-weight: 700;

    &-title {
      font-size: 20px;
      padding: 8px 12px;
    }

    &-content {
      padding: 8px 12px;
      font-size: 16px;
      border-top: 1px solid $setlink_form_background;

      .label {
        font-weight: 500;
      }
    }
  }

  &-arrow {
    position: absolute;
    display: block;
    width: 11.31px;
    height: 8px;
    overflow: hidden;
    background: transparent;
    transform: translateX(-50%);
    left: 50%;
    top: -8px;

    &-content {
      right: 0;
      left: 0;
      display: block;
      width: 8px;
      height: 8px;
      margin: auto;
      background-color: $white;
      transform: translateY(4px) rotate(45deg);
    }
  }
}