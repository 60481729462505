.button {
  color: $black;

  font-family: $tertiary_font_family;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  &__primary {
    background-color: $setlink_yellow;

    &--disabled {
      background-color: $setlink_grey;
      color: $setlink_disabled;
      cursor: default;
    }
  }

  &__secondary {
    background-color: $setlink_grey;

    &--disabled {
      background-color: $setlink_grey;
      color: $setlink_disabled;
      cursor: default;
    }
  }

  &__tertiary {
    background-color: $black;
    color: $white;

    &--disabled {
      background-color: $setlink_grey;
      color: $setlink_disabled;
      cursor: default;
    }
  }
}

.form-group {
  display: grid;
  grid-template-columns: 40% auto;
  column-gap: 16px;

  &__label {
    font-family: $tertiary_font_family;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: $setlink_black;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 30px;
    width: 100%;
  }

  &__input {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
  }

  &--has-error {
    .input {
      border: 1px solid $setlink_red;
    }
  }

  &__error-message {
    font-family: $secondary_font_family;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    font-style: italic;
    color: $setlink_accessible_red;
    align-self: end;
  }
}

.textarea {
  border-radius: 15px;
  padding: 0 15px;
  background: $white;
  display: inline-block;

  &--full-width {
    width: 100%;
    box-sizing: border-box;
  }

  &--with-border {
    border: 1px solid rgba(0, 0, 0, 0.5);

    textarea {
      border: none;
      resize: none;
      width: 100%;
      height: 100%;
      font-family: $tertiary_font_family;
      font-weight: 400;
      font-style: normal;
      line-height: 24px;
      font-size: 20px;
      min-height: 60px;
      box-sizing: border-box;

      &:focus-visible {
        outline: none;
      }

    }
  }

  // imitate textarea but not a textarea
  &-like {
    min-height: 52px;
    width: 270px;
    font-family: $tertiary_font_family;
    font-weight: 400;
    font-style: normal;
    line-height: 24px;
    font-size: 20px;
    padding: 6px 0;
  }

  &--readonly {
    background: $setlink_grey;
    color: $setlink_darkgrey;
  }
}

.inline-textarea,
.inline-input {
  border: none;
  outline-style: none;
  resize: none;
  font-family: $primary_font_family;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;

  &:focus-visible {
    outline: none;
  }
}

::-webkit-input-placeholder {
  color: $setlink_placeholder;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $setlink_placeholder;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $setlink_placeholder;
}

:-ms-input-placeholder {
  color: $setlink_placeholder;
}

::placeholder {
  color: $setlink_placeholder;
}

.input {
  border-radius: 15px;
  padding: 0 15px;
  background: $white;
  border: 1px solid $white;

  input[type='text'],
  input[type='number'] {
    height: 26px;
    background-color: $white;
    border: none;
    line-height: 26px;
    font-family: $secondary_font_family;
    font-size: 20px;
    outline: none;
    color: $setlink_black;

    &:focus-visible {
      outline: none;
    }
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  &--readonly {
    background: $setlink_grey;
    border: 1px solid $setlink_grey;

    input[type='text'],
    input[type='number'] {
      background: $setlink_grey;
      color: $setlink_darkgrey;
    }
  }
}

.select {
  border-radius: 15px;
  padding: 0 15px;
  background: $white;
  position: absolute;
  cursor: default;

  &--small {
    width: 151px;
  }

  &--medium {
    width: 240px;
  }

  &--empty {
    font-family: $tertiary_font_family;
    font-size: 20px;
    line-height: 30px;
    font-style: normal;
    font-weight: 400;
  }

  &::after {
    position: absolute;
    right: 10px;
    top: calc(50% - 5px);
    content: '';
    display: inline-block;
    background: url('../../icons/chevron-down.svg');
    width: 10px;
    height: 10px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 10px 10px;
  }

  &__placeholder {
    font-family: $tertiary_font_family;
    font-size: 20px;
    line-height: 30px;
    font-style: normal;
    font-weight: 400;
    color: $setlink_placeholder;
  }

  &__value {
    font-family: $tertiary_font_family;
    font-size: 20px;
    line-height: 30px;
    font-style: normal;
    font-weight: 400;
    color: $setlink_black;
  }

  &__option {
    font-family: $tertiary_font_family;
    font-size: 20px;
    line-height: 30px;
    font-style: normal;
    font-weight: 400;
    color: $setlink_black;
    background-color: $white;
    padding: 0 8px;
    cursor: pointer;

    &:hover {
      background-color: $setlink_grey;
    }

    &s {
      background: #fff;
      z-index: 5000;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;

      &-popup {
        display: none;

        &--overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          z-index: 4900;
        }

        &--active {
          display: block;
        }
      }
    }
  }
}

.radio {
  &__option {
    display: block;
    position: relative;
    cursor: pointer;

    &-label {
      font-family: $tertiary_font_family;
      font-size: 20px;
      line-height: 30px;
      font-style: normal;
      font-weight: 400;
      margin-left: 9px;
      display: inline-block;
    }

    input[type='radio'] {
      opacity: 0;
    }

    &::before {
      position: absolute;
      left: 0;
      top: calc(50% - 7px);
      width: 15px;
      height: 15px;
      background-image: url('../../icons/radio-button.svg');
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      content: ' ';
    }

    &--checked::before {
      background-image: url('../../icons/radio-button-checked.svg');
    }

    &--disable {
      cursor: not-allowed;

      &::before {
        background-image: url('../../icons/radio-button-disable.svg');
      }

      .radio__option-label {
        color: $setlink_disabled;
      }
    }
  }
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 29px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 1px;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid $setlink_lightgrey;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input[type='checkbox']:checked~.checkmark:after {
    display: block;
  }

  .checkmark:after {
    height: 12px;
    width: 15px;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../icons/check-mark-orange.svg');
  }
}

.static-placeholder {
  font-family: $primary_font_family;
  font-weight: 400;
  font-size: 11px;
  line-height: 19px;
  color: $setlink_grey;
  padding-left: 14px;
}